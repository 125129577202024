/* eslint-disable */
import React from "react";

export default function GiveScholarshipLandingPage() {
  return (
    <div className="bg-backcolor-50 p-8 sm:p-12 lg:p-20 font-roboto">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-primary-600 text-4xl lg:text-5xl font-bebas text-center mb-8">
          Updates to COhatch Give Scholarship Program
        </h1>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          As COhatch and our scholarship programs continue to grow, we are ever
          learning and evolving! We are so grateful to be able to provide access
          to our locations for nonprofits and local startups every year.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          In an effort to continue to grow these programs here at COhatch, we
          are instituting a monthly administrative fee to help cover a few of
          the costs that make running our locations possible.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          For nonprofits re-applying or applying for scholarships at COhatch
          after October 14th, 2024, we are instituting a $10/month administrative
          fee per person in each organization. This means if you have a team of
          three people, you’ll get access to COhatch for $30/month total.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          We know that this is a change for our scholars, but we also believe
          this administrative fee will allow us to continue to provide an
          excellent member experience to you and your organization.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          By submitting your scholarship renewal application and hitting submit
          here on this page, you will be accepting the new administrative fee on
          behalf of your organization.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          If you have questions about your scholarship or payment, please direct
          them to{" "}
          <a href="mailto:hello@cohatch.com" className="text-primary-400 underline">
            hello@cohatch.com
          </a>
          . For those who submit and are successfully renewed by the local team,
          you will hear from your Community Manager soon to get setup with the
          administrative fee going forward.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          Thank you for being a part of the COhatch community! We’re excited to
          continue to grow our scholarship programs with your help.
        </p>

        <button
          className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-3 px-6 rounded w-full sm:w-auto block mx-auto"
          onClick={() => alert("Application Submitted!")}
        >
          Submit Application
        </button>
      </div>
    </div>
  );
}
