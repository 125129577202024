/*eslint-disable*/
import React from "react";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import getAppsById from "./requests/getAppsByUserId";
import submitApplication from "../philanthropy/give_scholarships/submitApplication";
import {
	Input,
	SelectInputs,
} from "../../components/formvalidate/Input";
import { MAGIC_NUMBERS, APP_MARKETS, APP_LOCATIONS, libraries } from "../../constant";

function ReapplicationPage() {
	const { userState } = useUserContext();
	const accessToken = userState.accessToken;
	const userId = userState.id;
	const [apps, setApps] = React.useState([]);
	const [selectedApp, setSelectedApp] = React.useState(null);
	const [isChecked, setIsChecked] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const navigate = useNavigate();

	const methods = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			orgName: "",
			website: "",
			businessAddress: "",
			market: "",
			coLocation: "",
			orgAmount: "",
		},
		mode: 'onBlur',
	});

	const { reset, formState: { isValid, errors } } = methods;

	const getElevenMonthsAgo = () => {
		const today = new Date();
		today.setMonth(today.getMonth() - 11);
		return today;
	};

	const fetchApplicationByUserId = async (accessToken, userId) => {
		try {
			const response = await getAppsById(accessToken, userId);
			if (response.status === "success") {
				const elevenMonthsAgo = getElevenMonthsAgo();
				const filteredApps = response.data.filter(app => {
					const createdAt = new Date(app.createdAt);
					return createdAt.getFullYear() === elevenMonthsAgo.getFullYear() && createdAt.getMonth() === elevenMonthsAgo.getMonth();
				});
				setApps(filteredApps);
			} else {
				console.log("Error fetching applications");
			}
		} catch (error) {
			console.error("Error fetching apps: ", error);
		}
	};

	const generateCohatchMarket = () => {
		const cohatchMarket = [];
		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}
		return cohatchMarket.flat();
	};

	const generateCohatchLocation = () => {
		if (methods.watch().market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[methods.watch().market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	const handleOnChange = (app) => {
		setSelectedApp(app);
		setIsChecked(true);
		reset({
			firstName: app.firstName || "",
			lastName: app.lastName || "",
			email: app.email || "",
			phoneNumber: app.phoneNumber || "",
			orgName: app.orgName || "",
			website: app.website || "",
			businessAddress: app.businessAddress || "",
			market: app.market || "",
			coLocation: app.coLocation || "",
			orgAmount: app.orgAmount || "",
		});
	};

	const onSubmit = methods.handleSubmit(async (data) => {
		// Check if form is valid before submitting
		if (!isValid) return;

		await handleFormSubmit(data);
		navigate("/dashboard/volunteer/impact_stories/add");
	});
	const handleFormSubmit = async (data) => {
		try {
			setSubmitting(true);
			const givePayload = {
				...selectedApp,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				phoneNumber: data.phoneNumber,
				orgName: data.orgName,
				website: data.website,
				businessAddress: data.businessAddress,
				market: data.market,
				coLocation: data.coLocation,
				orgAmount: data.orgAmount,
				createdAt: new Date(),
			};
			const response = await submitApplication(givePayload, accessToken);
			if (response.status === "success") {
				setSubmitting(false);
			} else {
				setSubmitting(false);
				let displayMessage = response.message || "An error occurred.";
				console.log(displayMessage);
			}
		} catch (error) {
			setSubmitting(false);
			console.error("Submission error: ", error);
		}
	};

	useEffect(() => {
		fetchApplicationByUserId(accessToken, userId);
	}, [accessToken, userId]);

	return (
		<div className="p-6 flex justify-center min-h-screen bg-gray-50">
			<div className="max-w-5xl w-full bg-white shadow-md rounded-lg overflow-hidden">
				<div className="bg-gradient-to-r from-success-800 to-success-700 p-8 text-white text-center rounded-t-lg">
					<h1 className="text-2xl font-bold">2024 Application</h1>
				</div>

				<div className="p-8 lg:px-16">
					{apps.length > 0 && !isChecked ? (
						<div className="space-y-4">
							{apps.map((app, index) => (
								<div key={index} className="flex items-center justify-between border-b py-4">
									<div className="flex flex-col">
										<span className="capitalize text-lg font-semibold">{app.orgName}</span>
										<span className="text-sm text-gray-600">{app.type}</span>
									</div>
									<button
										onClick={() => handleOnChange(app)}
										className="inline-flex items-center px-4 py-2 bg-success-600 text-white font-medium rounded-md shadow-sm hover:bg-success-700 transition duration-300 ease-in-out"
									>
										Select
									</button>
								</div>
							))}
						</div>
					) : null}

					{isChecked ? (
						<div className="mt-10 space-y-6">
							<h2 className="text-lg font-semibold text-gray-700 mb-5">
								Please double-check that all information is correct.
							</h2>

							<FormProvider {...methods}>
								<form onSubmit={onSubmit} noValidate autoComplete="off" className="space-y-6">
									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* First Name Field */}
										<div>
											<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
												First Name
											</label>
											<Input
												name="firstName"
												required
												rules={{ required: "First Name is required" }}
												className="mt-1 block w-full"
											/>
											{errors.firstName && <p className="text-red-600 text-sm mt-1">{errors.firstName.message}</p>}
										</div>

										{/* Last Name Field */}
										<div>
											<label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
												Last Name
											</label>
											<Input
												name="lastName"
												required
												rules={{ required: "Last Name is required" }}
												className="mt-1 block w-full"
											/>
											{errors.lastName && <p className="text-red-600 text-sm mt-1">{errors.lastName.message}</p>}
										</div>
									</div>

									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* Email Field */}
										<div>
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Email
											</label>
											<Input
												name="email"
												type="email"
												required
												rules={{ required: "Email is required" }}
												className="mt-1 block w-full"
											/>
											{errors.email && <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>}
										</div>

										{/* Phone Number Field */}
										<div>
											<label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
												Phone Number
											</label>
											<Input
												name="phoneNumber"
												type="tel"
												required
												rules={{ required: "Phone Number is required" }}
												className="mt-1 block w-full"
											/>
											{errors.phoneNumber && <p className="text-red-600 text-sm mt-1">{errors.phoneNumber.message}</p>}
										</div>
									</div>

									{/* Organization Name Field */}
									<div>
										<label htmlFor="orgName" className="block text-sm font-medium text-gray-700">
											Organization Name
										</label>
										<Input
											name="orgName"
											type="text"
											required
											rules={{ required: "Organization Name is required" }}
											className="mt-1 block w-full"
										/>
										{errors.orgName && <p className="text-red-600 text-sm mt-1">{errors.orgName.message}</p>}
									</div>

									{/* Organization Website Field */}
									<div>
										<label htmlFor="website" className="block text-sm font-medium text-gray-700">
											Organization Website
										</label>
										<Input
											name="website"
											type="url"
											required
											rules={{ required: "Website is required" }}
											className="mt-1 block w-full"
										/>
										{errors.website && <p className="text-red-600 text-sm mt-1">{errors.website.message}</p>}
									</div>

									{/* Business Address Field */}
									<div>
										<label htmlFor="businessAddress" className="block text-sm font-medium text-gray-700">
											Organization Address
										</label>
										<Input
											name="businessAddress"
											required
											rules={{ required: "Business Address is required" }}
											className="mt-1 block w-full"
										/>
										{errors.businessAddress && <p className="text-red-600 text-sm mt-1">{errors.businessAddress.message}</p>}
									</div>

									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* COhatch City Select */}
										<div>
											<SelectInputs
												name="market"
												label="COhatch City"
												options={generateCohatchMarket()}
												required
												rules={{ required: "Market is required" }}
												className="mt-1 block w-full"
											/>
											{errors.market && <p className="text-red-600 text-sm mt-1">{errors.market.message}</p>}
										</div>

										{/* COhatch Location Select */}
										<div>
											<SelectInputs
												name="coLocation"
												label="COhatch Location"
												options={generateCohatchLocation()}
												required
												rules={{ required: "Location is required" }}
												className="mt-1 block w-full"
											/>
											{errors.coLocation && <p className="text-red-600 text-sm mt-1">{errors.coLocation.message}</p>}
										</div>
									</div>

									{/* Employee Access Select */}
									<div>
										<SelectInputs
											name="orgAmount"
											label="How many employees need access to COhatch?"
											options={[
												{ value: 1, label: "1" },
												{ value: 2, label: "2" },
												{ value: 3, label: "3" },
												{ value: 4, label: "4" },
												{ value: 5, label: "5" },
											]}
											required
											rules={{ required: "Number of employees is required" }}
											className="mt-1 block w-full"
										/>
										{errors.orgAmount && <p className="text-red-600 text-sm mt-1">{errors.orgAmount.message}</p>}
									</div>

									{/* Buttons */}
									<div className="flex items-center justify-between mt-8">
										<button
											className="text-sm font-medium text-success-600 hover:text-success-700 focus:outline-none focus:underline"
											onClick={() => setIsChecked(false)}
											type="button"
										>
											Back
										</button>
										<button
											className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-success-600 hover:bg-success-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success-500 transition duration-300 ease-in-out"
											type="submit"
											disabled={!isValid || submitting} // Ensure the button is disabled if the form is invalid
										>
											Next
										</button>
									</div>
								</form>
							</FormProvider>

						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default ReapplicationPage;
